<script lang="ts">
import { Vue, Component, VModel, Inject } from 'vue-property-decorator'
import { AmpliService } from '@/services/amplitude.service'

import PartnerDetail from '@/components/entity/PartnerDetail'
import InfoList from '@/components/entity/InfoList'
import Divider from '@/components/ui/Divider'
import Button from '@/components/ui/Button'
import Icons from '@/components/ui/Icons'

@Component({
  components: {
    PartnerDetail,
    InfoList,
    Divider,
    Button,
    IconArrowLeft: Icons.ArrowLeft,
    IconArrowRight: Icons.ArrowRight
  }
})
export default class RosskoDetail extends Vue {
  @Inject('ampli') readonly ampli!: AmpliService

  @VModel({ type: Boolean }) state!: boolean

  avatar = require('@/assets/images/rossko-avatar.svg')

  features = [
    'Более 1 500 000 запчастей​',
    'Широкий выбор запчастей на все марки и модели легкового и легкокоммерческого транспорта',
    'Выбор способа доставки – самовывоз из магазинов или пунктов выдачи СДЭК​',
    'Доставка за 2 часа​',
    'Подбор запчастей для моделей китайских авто в каталогах',
    'Оригинальные и аналоговые запчасти ​',
    'Гарантия возврата​',
    'Возврат 20% НДС​'
  ]

  onHotjarLinkClick (trackElementName: string) {
    this.ampli.trackElementClick({
      'Element name': trackElementName
    })
  }

  onActionClick () {
    this.ampli.trackElementClick({
      'Element name': 'кнопка войти в Rossko'
    })
  }

  onPrevClick () {
    this.$emit('prev')
  }

  onNextClick () {
    this.$emit('next')
  }
}
</script>

<template>
  <PartnerDetail
    v-model="state"
    title="Rossko"
    color="#FFF3F2"
    :image="avatar.default"
  >
    <template #info>
      <div class="gc-detail__info">
        <p>
          ROSSKO является ведущим дистрибьютором автозапчастей в России с широким региональным охватом. Склады и магазины открыты в <a target="_blank" href="https://rossko.ru/shipping_options/">129 городах страны</a>. Росско работает только по прямым контрактам с поставщиками, поэтому в наших каталогах гарантированно нет контрафакта.
        </p>
      </div>
    </template>

    <template #default>
      <Divider/>

      <InfoList
        title="Преимущества Rossko"
        :items="features"
      />

      <Divider/>

      <InfoList
        title="Как заказать запчасти"
        manual
        numerable
      >
        <li>
          Нажмите на кнопку «Перейти в Rossko»
        </li>

        <li>
          Авторизованные пользователи ЛК ППР сразу могут начать покупки
        </li>

        <li>
          Если пользователь не авторизован , то на странице авторизации необходимо ввести логин и пароль от ЛК ППР
        </li>

        <li>
          Если хотите разрешить сотруднику покупать запчасти, то создайте сотрудника и оставьте заявку на подключение его к покупке запчастей
        </li>

        <li>
          Укажите город ( временно некоторые города могут быть закрыты для заказа клиентам ППР на стороне партнера)
        </li>

        <li>
          Выбирайте запчасти, формируйте корзину и оформляйте заказ, выбрав способ доставки. – пункт самовывоза ROSSKO или пункт выдачи СДЭК Оплата будет производиться со счета в ППР
        </li>
      </InfoList>
    </template>

    <template #footer>
      <div class="gc-detail__actions">
        <Button
          class="gc-detail__ui-action-button"
          color="accent"
          tag="a"
          href="https://rossko.ru/ppr/auth/login"
          target="_blank"
          @click.native="onActionClick"
        >
          Перейти в Rossko
        </Button>

        <div class="gc-detail__ui-right-actions">
          <Button
            color="neutral"
            size="s"
            icon
            @click.native="onPrevClick"
          >
            <IconArrowLeft/>
          </Button>

          <Button
            color="neutral"
            size="s"
            icon
            @click.native="onNextClick"
          >
            <IconArrowRight/>
          </Button>
        </div>
      </div>
    </template>
  </PartnerDetail>
</template>

<style lang="scss" src="@/assets/scss/components/detail.scss"></style>
