<script lang="ts">
import { Vue, Component, Inject } from 'vue-property-decorator'

import { AmpliService } from '@/services/amplitude.service'

import Button from '@/components/ui/Button'
import Banner from '@/components/entity/Banner'
import TabContent from '@/components/entity/TabContent'
import FitServiceDetail from '@/components/entity/FitServiceDetail'
import RosskoDetail from '@/components/entity/RosskoDetail'

import InfoList from '@/components/entity/InfoList'
import InfoSection from '@/components/entity/InfoSection'
import InfoSlot from '@/components/entity/InfoSlot'
import InfoBlock from '@/components/entity/InfoBlock'
import InfoBlockGroup from '@/components/entity/InfoBlockGroup'

@Component({
  components: {
    Button,
    Banner,
    FitServiceDetail,
    RosskoDetail,
    InfoList,
    InfoSection,
    InfoSlot,
    InfoBlock,
    InfoBlockGroup,
    TabContent
  }
})
export default class Info extends Vue {
  @Inject('ampli') readonly ampli!: AmpliService

  modal = false
  fitServiceModal = false
  rosskoModal = false

  detailModalList = ['fitservice', 'rossko']

  activeDetail = ''
  activePartnerType = 'all'

  features = [
    'Работаем только с проверенными поставщиками',
    'География - вся Россия',
    'Возврат 20% НДС',
    'Оплата с единого счета ППР',
    'Единый комплект закрывающих документов'
  ]

  howToConnect = [
    'Нажмите на кнопку «Заявка на подключение»',
    'Укажите в заявке данные сотрудника, который будет управлять услугой',
    'Ожидайте, с вами свяжется наш менеджер в течение одного рабочего дня'
  ]

  partnersOptions = [
    { name: 'Ремонт', value: 'carfix' },
    { name: 'Запчасти', value: 'spareparts' }
  ]

  partners = [
    {
      id: 'fitservice',
      type: 'carfix',
      title: 'Fit Service',
      text: 'Cамая быстрорастущая сеть автосервисов по всей России от Москвы до Владивостока',
      image: require('@/assets/images/fitservice-avatar.png'),
      color: '#F5F6F7'
    },
    {
      id: 'rossko',
      type: 'spareparts',
      title: 'Rossko',
      text: 'Онлайн-магазин запчастией. Более 1 500 000 артикулов',
      image: require('@/assets/images/rossko-avatar.svg'),
      color: '#FFF3F2'
    }
  ]

  partnerFitServiceData = {
    title: 'О партнeре',
    text: 'FIT SERVICE — это не просто самая быстро растущая сеть автосервисов по всей России от Москвы до Владивостока. Это тысячи клиентов, которые нам доверяют. Это дружная команда людей, вдохновленных общей идеей и общим делом. Это строгие стандарты качества во всем и постоянная поддержка наших клиентов, где бы они ни находились.',
    image: require('@/assets/images/FitServiceFrame.png')
  }

  get filteredPartners () {
    if (this.activePartnerType === 'all') {
      return this.partners
    }

    return this.partners.filter(_i => _i.type === this.activePartnerType)
  }

  onActionClick (id: string) {
    this.activeDetail = id
  }

  onHotjarLinkClick (trackElementName: string) {
    this.ampli.trackElementClick({
      'Element name': trackElementName
    })
  }

  onEnterOnFitServiceClick () {
    this.ampli.trackElementClick({
      'Element name': 'кнопка войти в фит'
    })
  }

  onPrevDetailClick () {
    const current = this.detailModalList.findIndex(_i => _i === this.activeDetail)
    const prev = this.detailModalList[current - 1]

    this.activeDetail = prev ?? this.detailModalList[this.detailModalList.length - 1]
  }

  onNextDetailClick () {
    const current = this.detailModalList.findIndex(_i => _i === this.activeDetail)
    const next = this.detailModalList[current + 1]

    this.activeDetail = next ?? this.detailModalList[0]
  }
}
</script>

<template>
  <div class="p-info">
    <div class="p-info__main">
      <section class="p-info__section">
        <InfoList
          title="Преимущества услуги"
          :items="features"
        />
      </section>

      <section class="p-info__section">
        <InfoSlot title="Наши партнеры">
          <TabContent class="p-info__partner-tabs" v-model="activePartnerType" :options="partnersOptions"/>

          <InfoBlockGroup>
            <InfoBlock
              v-for="item in filteredPartners"
              :key="item.id"
              :image="item.image.default"
              :title="item.title"
              :description="item.text"
              :color="item.color"
              @action-click="onActionClick(item.id)"
            />
          </InfoBlockGroup>
        </InfoSlot>
      </section>
    </div>

    <FitServiceDetail :value="activeDetail === 'fitservice'" @input="activeDetail = ''" @prev="onPrevDetailClick" @next="onNextDetailClick"/>

    <RosskoDetail :value="activeDetail === 'rossko'" @input="activeDetail = ''" @prev="onPrevDetailClick" @next="onNextDetailClick"/>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
